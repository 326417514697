<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div>
        <autocomplete-address
          v-model="address"
          placeholder="Enter the property address">
        </autocomplete-address>
      </div>

      <template v-if="address">
        <v-select
          v-model="type"
          style="max-width: 300px"
          label="Property type"
          :items="propertyTypes">
        </v-select>
      </template>

      <div style="text-align: center">
        <v-btn color="primary" @click="submit" :disabled="isSubmitting || address === null">
          {{ isSubmitting ? 'Please Wait' : 'Save Property' }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>


<script>
import AutocompleteAddress from "@/components/AutocompleteAddress";

export default {
  components: {AutocompleteAddress},
  props:      {
    contactId: {
      required: true
    }
  },

  computed: {
    propertyTypes() {
      return ['House', 'Condo'];
    }
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {

        this.isSubmitting = true;

        this.$api.contacts.createProperty(this.contactId, {
          address:       this.address,
          property_type: this.type,
        })
          .then((response) => {
            this.isSubmitting = false;

            this.$emit('submitted', response.data);
          });
      }
    }
  },

  data() {
    return {
      type:         null,
      address:      null,
      valid:        true,
      isSubmitting: false
    }
  }
}
</script>
