<template>
  <div>
    <template v-if="applications === false">
      <v-alert border="top" color="red lighten-2" dark>
        An alert occurred. Please try again.
      </v-alert>
    </template>

    <template v-else>
      <h3 class="page-header">Rater Applications</h3>

      <template v-if="applications !== null">
        <div style="display: flex; margin-bottom: 20px; align-items: center">
          <div style="flex:1">
            <strong>{{ total }}</strong> Rater applications

            <template v-if="sso">
              <form method="post" :action="sso.url" target="_blank"
                    style="display: inline; margin-left: 10px">
                <input type="hidden" name="Authorization" :value="sso.token"/>
                <v-btn small color="primary" type="submit">Connect to Dashboard</v-btn>
              </form>
            </template>
          </div>

          <div>
            <v-btn @click="startApplication" color="primary">Start New Application</v-btn>
          </div>
        </div>
      </template>

      <v-dialog
        v-if="newApplicationFormIsShown"
        v-model="newApplicationFormIsShown"
        persistent
        max-width="700px">
        <v-card>
          <v-card-text>
            <new-application-form @submitted="onNewApplication"></new-application-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="newApplicationFormIsShown = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="newApplicationSubmitted" :timeout="4000" centered :multi-line="true">
        <strong>Application created!</strong>
        <div style="margin-top: 4px">Fill missing details to generate quotes.</div>

        <template v-slot:action="{ attrs }">
          <v-btn v-if="latestApplication" target="_blank"
                 :href="latestApplication.attributes.link" color="primary" text
                 @click="updateSuccessful = false">
            Fill application
          </v-btn>
        </template>
      </v-snackbar>

      <v-data-table
        :headers="tableHeaders"
        :items="tableItems"
        :loading="applications === null"
        :hide-default-footer="true"
        :disable-pagination="true">

        <template v-slot:item.name="{ item }">
          <div style="line-height: 1.2">
            <div style="font-weight: bold">{{ item.name }}</div>
            <div><small>{{ item.email }}</small></div>
          </div>
        </template>

        <template v-slot:item.lob="{ item }">
          <span style="text-transform: capitalize">{{ item.lob }}</span>
        </template>

        <template v-slot:item.status="{ item }">
          <template v-if="item.status === 'pending'">
            <v-chip label color="info">In progress</v-chip>
          </template>
          <template v-else>
            <v-chip label color="success">
              <strong style="margin-right: 5px">{{ item.quotes_count }}</strong>
              {{ item.quotes_count > 1 ? 'Quotes' : 'Quote' }} received
            </v-chip>
          </template>
        </template>

        <template v-slot:item.actions="{ item }">
          <a target="_blank" :href="item.link">
            {{ item.status === 'pending' ? 'Continue' : 'View' }}</a>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.page-header {
  font-family: 'Roboto', serif;
  font-size: 32px;
  color: #422F2F;
  margin-bottom: 15px;
}
</style>

<script>
import {mapGetters} from "vuex";
import NewApplicationForm from './components/NewApplicationForm/index';

export default {
  components: {
    NewApplicationForm
  },

  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),

    tableHeaders() {
      return [
        {
          text:     'Contact',
          align:    'start',
          sortable: true,
          value:    'name',
        },
        {
          text:     'Line of Business',
          align:    'start',
          sortable: true,
          value:    'lob',
        },
        {
          text:     'Application',
          align:    'start',
          sortable: true,
          value:    'application',
        },
        {
          text:     'Status',
          align:    'start',
          sortable: true,
          value:    'status',
          width:    '200px',
        },
        {
          text:     'Date',
          align:    'start',
          sortable: true,
          value:    'date',
          width:    '220px'
        },
        {
          text:  'Actions',
          align: 'end',
          width: '150px',
          value: 'actions'
        },
      ];
    },

    tableItems() {
      let items = [];

      if (this.applications) {
        _.forEach(this.applications, (application) => {
          let contact = application.related.contact.data;

          let lob = [];

          _.forEach(application.related.policy_types.data, (type) => {
            lob.push((type.attributes.category + ' ' + type.attributes.name).toLowerCase());
          });

          items.push({
            id:           application.id,
            name:         contact.attributes.name,
            email:        contact.attributes.email,
            application:  application.attributes.application_identifier,
            lob:          lob.join(', '),
            quotes_count: application.attributes.quotes_count,
            status:       application.attributes.status,
            link:         application.attributes.link,
            date:         this.$moment(application.attributes.created_at).format('MMMM Do YYYY h:m a')
          });
        });
      }

      return items;
    }
  },

  methods: {
    loadRaterApplications() {
      this.$api.rater.loadApplications()
        .then((response) => {
          this.applications = response.data;
          this.total        = response.meta.total;
          this.sso          = response.sso;
        })
        .catch(() => {
          this.applications = false;
        });
    },

    startApplication() {
      this.newApplicationFormIsShown = true;
    },

    onNewApplication(newApplication) {
      this.newApplicationFormIsShown = false;
      this.newApplicationSubmitted   = true;
      this.latestApplication         = newApplication;

      this.applications.push(newApplication);

      window.open(newApplication.attributes.link, '_blank');
    }
  },

  beforeMount() {
    this.loadRaterApplications();
  },

  data() {
    return {
      applications:              null,
      sso:                       null,
      total:                     null,
      newApplicationFormIsShown: false,
      newApplicationSubmitted:   false,
      latestApplication:         null
    }
  }
}
</script>
