<template>
  <div>
    <template v-if="isCreatingForm === 'auto'">
      <div class="associated-section">
        <div>Add new auto for contact</div>
        <v-btn @click="isCreatingForm = null;">Cancel</v-btn>
      </div>

      <new-auto-form :contactId="contactId" @submitted="onNewAuto"></new-auto-form>
    </template>

    <template v-else-if="isCreatingForm === 'property'">
      <div class="associated-section">
        <div>Add new property for contact</div>
        <v-btn @click="isCreatingForm = null;">Cancel</v-btn>
      </div>

      <new-property-form :contactId="contactId" @submitted="onNewProperty"></new-property-form>
    </template>

    <template v-else-if="contactAssets !== null">
      <!-- Auto section -->
      <template v-if="requiresAutosAssets">
        <div class="autos">
          <div class="associated-section">
            <div>
              {{
                contactAutos.length === 0 ? 'Add new auto info' : 'Select autos in this quote'
              }}
            </div>
            <v-btn color="primary" :small="true" @click="showNewForm('auto')">Add Auto</v-btn>
          </div>

          <!-- List of vehicles with checkboxes -->
          <v-list v-if="contactAutos.length > 0" class="list-assets">
            <v-list-item-group v-model="selectedAssets.autos" multiple>
              <template v-for="auto in contactAutos">
                <v-list-item :value="auto.id">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active" color="primary"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title style="font-weight: 600">
                        {{ auto.attributes.make }} - {{ auto.attributes.model }}
                        ({{ auto.attributes.year }})
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="auto.attributes.identifier">
                        VIN : {{ auto.attributes.identifier }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-divider></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </div>
      </template>

      <!-- Property section -->
      <template v-if="requiresPropertiesAssets">
        <div class="properties">
          <div class="associated-section">
            <div>
              {{
                contactProperties.length === 0 ? 'Add new property info' : 'Select properties in this quote'
              }}
            </div>
            <v-btn color="primary" :small="true" @click="showNewForm('property')">Add Property
            </v-btn>
          </div>

          <!-- List of properties -->
          <v-list v-if="contactProperties.length > 0" class="list-assets">
            <v-list-item-group v-model="selectedAssets.properties" multiple>
              <template v-for="property in contactProperties">
                <v-list-item :value="property.id">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active" color="primary"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title style="font-weight: 600">
                        {{ property.attributes.address.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-divider></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </div>
      </template>

      <div style="text-align: center; margin-top: 30px">
        <v-btn color="primary" @click="submit">
          {{
            selectedAssets.autos.length === 0 && selectedAssets.properties.length === 0 ? 'Or Continue' : 'Confirm selection'
          }}
        </v-btn>
      </div>
    </template>

    <template v-else>
      <div class="loading">Please wait ...</div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.associated-section {
  display: flex;
  align-items: center;

  div {
    flex: 1;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: 'Roboto Slab', serif;
    color: #422F2F;
  }

  button {
    font-size: 0.75rem;
    width: 120px;
  }
}

.autos, .properties {
  .list-assets {
    .v-list-item--link:before {
      background-color: white;
    }

    .v-list-item__subtitle {
      font-size: 0.8rem;
    }
  }
}

.autos + .properties {
  margin-top: 20px;
}

.loading {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'Roboto Slab', serif;
  color: #422F2F;
}
</style>

<script>
import NewAutoForm from './components/NewAutoForm';
import NewPropertyForm from './components/NewPropertyForm';

export default {
  props: {
    contact:     {
      required: true
    },
    policyTypes: {
      required: true
    }
  },

  components: {
    NewAutoForm,
    NewPropertyForm
  },

  computed: {
    contactId() {
      return this.contact.id;
    },

    contactAutos() {
      return this.contactAssets.vehicles || [];
    },

    contactProperties() {
      return this.contactAssets.properties || [];
    },

    requiresAutosAssets() {
      return this.associatedAssets.indexOf('autos') !== -1;
    },

    requiresPropertiesAssets() {
      return this.associatedAssets.indexOf('properties') !== -1;
    },

    associatedAssets() {
      let associatedAssets = [];

      _.each(this.policyTypes, (policyType) => {
        if (policyType.attributes.associated_assets.indexOf('autos') !== -1) {
          associatedAssets.push('autos');
        }
        if (policyType.attributes.associated_assets.indexOf('properties') !== -1) {
          associatedAssets.push('properties');
        }
      });

      return associatedAssets;
    }
  },

  methods: {
    loadAssetsData() {
      if (this.contactId) {
        this.$api.contacts.getAssets(this.contactId)
          .then((response) => {
            this.contactAssets = response.data;

            if (this.requiresAutosAssets) {
              _.forEach(this.contactAutos, (auto) => {
                this.selectedAssets.autos.push(auto.id);
              });
            }

            if (this.requiresPropertiesAssets) {
              _.forEach(this.contactProperties, (auto) => {
                this.selectedAssets.properties.push(auto.id);
              });
            }
          })
          .catch(() => {
            alert('Could not retrieve user info');
          });
      }
    },

    onNewAuto(auto) {
      this.contactAssets.vehicles.push(auto);

      this.selectedAssets.autos.push(auto.id);

      this.isCreatingForm = null;
    },

    onNewProperty(property) {
      this.contactAssets.properties.push(property);

      this.selectedAssets.properties.push(property.id);

      this.isCreatingForm = null;
    },

    showNewForm(value) {
      this.isCreatingForm = value;
    },

    submit() {
      let assets = {};

      if (this.requiresAutosAssets) {
        assets.autos = [];

        _.forEach(this.contactAutos, (auto) => {
          if (this.selectedAssets.autos.indexOf(auto.id) !== -1) {
            assets.autos.push(auto);
          }
        });
      }

      if (this.requiresPropertiesAssets) {
        assets.properties = [];

        _.forEach(this.contactProperties, (auto) => {
          if (this.selectedAssets.properties.indexOf(auto.id) !== -1) {
            assets.properties.push(auto);
          }
        });
      }

      this.$emit('submitted', assets);
    }
  },

  beforeMount() {
    if (this.associatedAssets.length === 0) {
      this.$emit('submitted', {});
    } else {
      this.loadAssetsData();
    }
  },

  data() {
    return {
      contactAssets:  null,
      selectedAssets: {
        autos:      [],
        properties: []
      },
      isCreatingForm: null
    }
  }
}
</script>
