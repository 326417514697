<template>
  <div>
    <div v-if="makes !== null">
      <div style="display: flex; align-items: center">
        <div style="margin-right: 15px">Do you know your VIN?</div>
        <v-text-field
          v-model="vin"
          :disabled="isLoadingVin"
          :loading="isLoadingVin"
          single-line
          label="Enter VIN to find data">
        </v-text-field>
      </div>

      <div v-if="vinStatus !== true" style="margin-bottom: 15px">
        <template v-if="vinStatus === false">
          <strong style="color:red">VIN is invalid or unknown</strong>. Search vehicle below:
        </template>
        <template v-else>
          Or search vehicle below:
        </template>
      </div>

      <v-row>
        <v-col cols="5">
          <v-autocomplete
            :items="makes"
            :dense="true"
            :disabled="isLoadingModels"
            :readonly="vinStatus === true"
            :loading="isLoadingModels"
            v-model="selectedMakeId"
            item-text="attributes.name"
            item-value="id"
            label="Select Vehicle Make"
            solo>
          </v-autocomplete>
        </v-col>
        <v-col v-if="selectedMakeId !== null && makeModels" cols="7">
          <v-autocomplete
            :items="makeModels"
            v-model="selectedModelId"
            :dense="true"
            :readonly="vinStatus === true"
            :clearable="vinStatus !== true"
            item-text="attributes.name"
            item-value="id"
            label="Select body type"
            solo>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ data.item.attributes.model }} </strong>
                  <span style="font-size: 0.9rem">{{ data.item.attributes.body }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <small>{{ data.item.attributes.year }}</small>
              </v-list-item-action>
            </template>

          </v-autocomplete>
        </v-col>
      </v-row>

      <template v-if="selectedModelId">
        <div style="margin-bottom: 10px">
          <strong>Vehicle Details:</strong>
        </div>

        <div style="display: flex; gap: 15px">
          <div style="flex:1">
            <v-select
              :error-messages="errorDetails.indexOf('ownershipType') !== -1 ? ['Required'] : []"
              v-model="ownershipType"
              label="Vehicle ownership type"
              item-text="label"
              item-value="value"
              :items="[{value:'owned', label:'Owned'}, {value: 'leased', label: 'Leased'}, {value: 'lien', label: 'Lien'}]">
            </v-select>
          </div>

          <div style="flex:1">
            <v-select
              :error-messages="errorDetails.indexOf('newCar') !== -1 ? ['Required'] : []"
              v-model="newCar"
              label="Was the car new when purchased?"
              item-text="label"
              item-value="value"
              :items="[{value:'1', label:'Yes'}, {value: '0', label: 'No'}]">
            </v-select>
          </div>
        </div>

        <div style="display: flex; gap: 15px">
          <div style="flex:1">
            <v-select
              :error-messages="errorDetails.indexOf('primaryUse') !== -1 ? ['Required'] : []"
              v-model="primaryUse"
              label="What is the primary use of this vehicle?"
              item-text="label"
              item-value="value"
              :items="[{value:'business', label:'Business'}, {value: 'pleasure', label: 'Pleasure'}, {value: 'farming', label: 'Farming'}, {value: 'to_from_work', label: 'To/From Work'}, {value: 'to_from_school', label: 'To/From School'}]">
            </v-select>
          </div>

          <div style="flex:1">
            <v-text-field
              v-model="annualMileage"
              :error-messages="errorDetails.indexOf('annualMileage') !== -1 ? ['Required'] : []"
              label="Annual mileage driven">
            </v-text-field>
          </div>
        </div>

        <div style="text-align: center">
          <v-btn color="primary" @click="submit" :disabled="isSubmitting">
            {{ isSubmitting ? 'Please Wait' : 'Save Vehicle' }}
          </v-btn>
        </div>
      </template>
    </div>
    <div v-else style="text-align: center; margin-top: 15px">
      <div class="loading">Please wait ...</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'Roboto Slab', serif;
  color: #422F2F;
}
</style>

<script>
export default {
  props: {
    contactId: {
      required: true
    }
  },

  watch: {
    selectedMakeId() {
      if (this.vinStatus === true) {
        return;
      }

      this.selectedModelId = null;
      this.models          = null;

      if (this.selectedMakeId !== null) {
        this.isLoadingModels = true;

        this.$api.autos.getMakeModels(this.selectedMakeId)
          .then((response) => {
            this.models          = response.data;
            this.isLoadingModels = false;
          });
      }
    },

    vin() {
      this.vinStatus = null;

      if (this.vin.length === 17) {
        this.isLoadingVin = true;

        this.$api.autos.getVinData(this.vin)
          .then((response) => {
            if (response.data.match === false) {
              this.vinStatus = false;
            } else {
              this.vinStatus       = true;
              this.selectedMakeId  = response.data.make.id;
              this.models          = [response.data.vehicle];
              this.selectedModelId = response.data.vehicle.id;
            }

            this.isLoadingVin = false;
          });

      } else {
        this.selectedMakeId  = null;
        this.selectedModelId = null;
        this.models          = null;
      }
    }
  },

  computed: {
    makeModels() {
      if (this.selectedMakeId === null || this.models === null) {
        return undefined;
      }

      let modelsPerYears = {};

      _.forEach(this.models, (model) => {
        if (modelsPerYears[model.attributes.year] === undefined) {
          modelsPerYears[model.attributes.year] = [];
        }

        modelsPerYears[model.attributes.year].push(model);
      });

      let models = [];
      _.forEach(modelsPerYears, (data, year) => {
        models.push({header: year});
        _.forEach(data, (model) => {
          models.push(model);
        });
      });

      return models;
    }
  },

  methods: {
    submit() {
      this.errorDetails = [];

      if (this.primaryUse === null) {
        this.errorDetails.push('primaryUse');
      }
      if (this.ownershipType === null) {
        this.errorDetails.push('ownershipType');
      }
      if (this.newCar === null) {
        this.errorDetails.push('newCar');
      }
      if (this.annualMileage === null || this.annualMileage === '') {
        this.errorDetails.push('annualMileage');
      }

      if (this.errorDetails.length !== 0) {
        return;
      }

      this.isSubmitting = true;

      this.$api.contacts.createAuto(this.contactId, {
        model_id:   this.selectedModelId,
        vin:        this.vin,
        parameters: {
          annual_mileage: this.annualMileage,
          primary_use:    this.primaryUse,
          ownership_type: this.ownershipType,
          new_car:        this.newCar === '1'
        }
      })
        .then((response) => {
          this.isSubmitting = false;

          this.$emit('submitted', response.data);
        });
    }
  },

  beforeMount() {
    this.$api.autos.getMakes()
      .then((response) => {
        this.makes = response.data;
      });
  },

  data() {
    return {
      vin:             '',
      vinStatus:       null,
      makes:           null,
      models:          null,
      primaryUse:      null,
      ownershipType:   null,
      newCar:          null,
      annualMileage:   null,
      errorDetails:    [],
      isLoadingModels: false,
      isLoadingVin:    false,
      selectedMakeId:  null,
      selectedModelId: null,
      isSubmitting:    false
    }
  }
}
</script>
