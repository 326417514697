<template>
  <div>
    <p style="margin-bottom: 20px">Select the <strong>Line of businesses</strong> for this quote. You can select multiple at once.</p>

    <v-tabs v-model="policyTypesCategory">
      <v-tab key="personal">Personal</v-tab>
      <!--<v-tab key="commercial">Commercial</v-tab>-->
    </v-tabs>

    <template v-if="displayedPolicyTypes.length > 0">

      <v-list dense class="policy-types">
        <v-list-item-group v-model="selectedPolicyTypesIds" multiple>
          <v-list-item v-for="type in displayedPolicyTypes" :value="type.id">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active" color="primary"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>{{ type.attributes.name}}</v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <div style="text-align: center">
        <v-btn color="primary" :disabled="selectedPolicyTypes.length === 0" @click="submit">
          Confirm Selection
        </v-btn>
      </div>

    </template>
  </div>
</template>

<style lang="scss" scoped>
.policy-types {
  margin-top: 10px;
  margin-bottom:10px;
  max-height: 400px;
  overflow: auto;

  .v-list-item--link:before {
    background-color: white;
  }
}
</style>

<script>
export default {
  computed: {
    displayedPolicyTypes() {
      let types = [];

      _.forEach(this.policyTypes, (policyType) => {
        if (this.policyTypesCategory === 0 && policyType.attributes.category === 'personal') {
          types.push(policyType);
        }
        if (this.policyTypesCategory === 1 && policyType.attributes.category === 'commercial') {
          types.push(policyType);
        }
      });

      return types;
    },

    selectedPolicyTypes() {
      let types = [];

      _.forEach(this.policyTypes, (policyType) => {
        if (this.selectedPolicyTypesIds.indexOf(policyType.id) !== -1) {
          types.push(policyType);
        }
      });

      return types;
    }
  },

  watch: {
    policyTypesCategory() {
      this.selectedPolicyTypesIds = [];
    }
  },

  methods: {
    loadPolicyTypes() {
      this.$api.policyTypes.index({'rater': true})
        .then((response) => {
          this.policyTypes = response.data;
        });
    },

    submit() {
      this.$emit('submitted', this.selectedPolicyTypes);
    }
  },

  beforeMount() {
    this.loadPolicyTypes();
  },

  data() {
    return {
      policyTypes:            null,
      selectedPolicyTypesIds: [],
      policyTypesCategory:    0,
    }
  }
}
</script>
