<template>
  <div>
    <p>First, you need to select the <strong>primary contact</strong> for this quote. You can search
      for an existing one or create a new one that will also be added to the AMS.</p>

    <template v-if="isCreatingNewContact">

      <div class="new-contact-form">
        <div style="display: flex; align-items: center">
          <div style="flex: 1">
            <div class="contact-form-title">Create New Contact</div>
            <p style="font-size: 0.9em; margin-bottom: 0">
              Note: This contact will also be added to your AMS
            </p>
          </div>
          <div style="cursor: pointer; color:#aaa" @click="isCreatingNewContact = false; newContactCreated = null">
            Cancel
          </div>
        </div>

        <template v-if="newContactCreated">
          <v-alert dense text type="success" :icon="false" style="margin-top: 20px">
            <v-row align="center">
              <v-col class="grow">
                This contact has been successfully created.
              </v-col>
              <v-col class="shrink">
                <v-btn color="success" @click="selectContact(newContactCreated)">Continue</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </template>
        <template v-else>
          <new-contact-form @submitted="onNewContactCreated"></new-contact-form>
        </template>
      </div>

    </template>
    <template v-else>
      <div class="contact-label" style="margin-bottom: 20px">Primary Contact</div>

      <v-text-field
        v-model="contactSearch"
        :loading="isSearchingContact"
        label="Search by name or email" solo>
      </v-text-field>

      <div style="margin-top: -15px" v-if="Array.isArray(searchResults)">
        <template v-if="searchResults.length === 0">
          <div style="text-align: center; margin-bottom: 15px">
            No matches found
          </div>
        </template>
        <template v-else>
          <div style="margin-bottom: 10px">
            <strong>{{ searchResults.length }}
              {{ searchResults.length > 1 ? 'matches' : 'match' }} found:</strong>
          </div>

          <v-list dense class="contacts-match">
            <v-list-item v-for="contact in searchResults" :key="contact.id">
              <v-list-item-content>

                <div class="contact">
                  <div style="line-height: 1.2">
                    <div class="name">{{ contact.attributes.name }}</div>
                    <div class="email">{{ contact.attributes.email || '-' }}</div>
                  </div>
                  <v-btn small color="primary" @click="selectContact(contact)">Select</v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </template>
      </div>

      <div>
        or
        <v-btn small color="primary" @click="createContact" style="margin-left: 5px">
          Create a new One
        </v-btn>
      </div>
    </template>

  </div>
</template>

<style lang="scss" scoped>
.contacts-match {
  max-height: 200px;
  margin-bottom: 15px;
  border: solid 1px #e0e0e0;
  overflow: auto;
  border-radius: 5px;

  .v-list-item + .v-list-item .contact {
    border-top: solid 1px #efefef;
    padding-top: 10px;
  }

  .contact {
    display: flex;
    align-items: center;

    & > div {
      flex: 1;
    }

    .name {
      font-weight: bold;
    }

    .email {
      font-size: 0.9em;
    }
  }
}

.contact-label, .contact-form-title {
  font-weight: 600;
  font-family: 'Roboto Slab', serif;
  color: #422F2F;
}

.new-contact-form {
  box-shadow: 0 2px 10px 0 rgb(94 86 105 / 10%);
  padding: 15px 0;
}
</style>

<script>
import NewContactForm from './components/NewContactForm';

export default {
  components: {
    NewContactForm
  },

  watch: {
    contactSearch() {
      this.searchContacts();
    }
  },

  methods: {
    searchContacts: _.debounce(function() {
      if (this.contactSearch === '') {
        this.searchResults = null;
      } else {
        this.isSearchingContact = true;

        this.$api.contacts.index({search: this.contactSearch})
          .then((response) => {
            this.searchResults      = response.data;
            this.isSearchingContact = false;
          });
      }
    }, 500),

    selectContact(contact) {
      this.$emit('contact-selected', contact);
    },

    createContact() {
      this.isCreatingNewContact = true;
    },

    onNewContactCreated(contact) {
      this.newContactCreated = contact;
    }
  },

  data() {
    return {
      contactSearch:        '',
      isSearchingContact:   false,
      isCreatingNewContact: false,
      newContactCreated:    null,
      searchResults:        null
    }
  }
}
</script>
