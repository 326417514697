<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="first_name"
              label="First Name *"
              :rules="[requiredRule]"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="last_name"
              label="Last Name *"
              :rules="[requiredRule]"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row style="margin-top: 0">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="email"
              label="E-mail *"
              :rules="[requiredRule, emailRule]"
              required></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="phone_number"
              label="Phone Number"
              :rules="[phoneRule]"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row style="margin-top: 0">
          <v-col cols="12">
            <autocomplete-address
              v-model="address"
              placeholder="Address (street, city, country)">
            </autocomplete-address>
          </v-col>
        </v-row>

      </v-container>

      <div style="text-align: center">
        <v-btn
          :disabled="isSubmitting"
          color="primary"
          @click="validate">
          Create New Contact
        </v-btn>
      </div>

    </v-form>
  </div>
</template>

<script>
import AutocompleteAddress from "@/components/AutocompleteAddress";

export default {
  components: {AutocompleteAddress},
  computed:   {
    requiredRule() {
      return v => !!v || 'Field is required';
    },

    emailRule() {
      return v => /.+@.+\..+/.test(v) || 'E-mail must be valid';
    },

    phoneRule() {
      return function(value) {
        if (value) {
          let number = '';
          for (let i = 0; i < value.length; i++) {
            if (/^\d$/.test(value[i])) {
              number += value[i];
            }
          }

          if (number.length !== 10) {
            return 'Phone number is be valid'
          }
        }

        return true;
      }
    }
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.isSubmitting = true;

        this.$api.contacts.create({
          first_name:   this.first_name,
          last_name:    this.last_name,
          email:        this.email,
          phone_number: this.phone_number,
          address:      this.address
        })
          .then((response) => {
            this.$emit('submitted', response.data);
          })
          .catch(() => {
            alert('An error occurred. Try again');
          })
          .then(() => {
            this.isSubmitting = false;
          })
      }
    }
  },

  data() {
    return {
      valid:        true,
      isSubmitting: false,
      first_name:   null,
      last_name:    null,
      email:        null,
      phone_number: null,
      address:      null
    }
  }
}
</script>
